/* Global Styles */
:root {
  --primary-color: #a40909;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #a40909;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  /* max-width: 1100px; */
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.max-width {
  width: 100%;
  margin: 0;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.px {
  padding: 0 0.5rem;
}
.px-1 {
  padding: 0 1rem;
}
.px-2 {
  padding: 0 2rem;
}
.px-3 {
  padding: 0 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m-0 {
  margin: 0;
}
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.mx {
  margin: 0 0.5rem;
}
.mx-1 {
  margin: 0 1rem;
}
.mx-2 {
  margin: 0 2rem;
}
.mx-3 {
  margin: 0 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 0.5rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.8;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn-small {
  width: 5rem;
}

.btn-medium {
  width: 10rem;
}

.btn-large {
  width: 15rem;
}

.btn-block {
  opacity: 0.8;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  /* color: var(--primary-color); */
  opacity: 0.8;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Landing Page */
.landing {
  position: relative;
  background: url('./img/showcase.jpg') no-repeat center center/cover;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about {
  margin: auto;
  color: var(--dark-color);
  text-align: center;
  width: 80%;
}
/* Login Page */
.login {
  position: relative;
  margin: 2px auto;
  width: 30%;
  padding: 2%;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px #333;
}
/* Rules Page */
.rules {
  width: 80%;
  margin: auto;
}
/* Menu Page */
.menu {
  position: relative;
  height: 100vh;
  /* border: black 2px solid; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  text-align: center;
}

.menu-buttons {
  display: flex;
  height: 20rem;
  flex-direction: column;
  box-shadow: 0 0 2px #333;
  width: 30%;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1.5rem;
  /* border: black 2px solid; */
}

.menu-item {
  text-align: left;
  padding: 0 0.5rem;
}

.menu-top {
  height: 20%;
  width: 100%;
  /* border: black 2px solid; */
}

/* Game Page */
.game {
  display: flex;
  height: 100vh;
}

.game-panel {
  width: 20%;
  height: 80%;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 0 2px #333;
  border-radius: 0.5rem;
}

.game-table {
  width: 75%;
  height: 80%;
  margin: 0.5rem;
  background: rgb(5, 126, 4);
  background: linear-gradient(
    90deg,
    rgba(5, 126, 4, 1) 15%,
    rgba(74, 180, 4, 1) 50%,
    rgba(5, 126, 4, 1) 85%
  );
  border-radius: 0.5rem;
}

.game-buttons {
  height: 3rem;
  margin: 2rem 0 0 0;
  /* border: solid 1px black; */
  display: flex;
  justify-content: center;
}

.game-middle {
  height: 30%;
  /* border: solid 1px black; */
  margin: 2rem 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.game-hand {
  height: 20%;
  margin: 1rem 0;
  /* border: solid 1px black; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.game-banner {
  height: 20%;
  display: flex;
  justify-content: center;
  /* border: solid 1px black; */
  color: #fff;
}

.landlord {
  color: var(--primary-color);
}

.credit {
  font-size: 0.75rem;
  display: flex;
  justify-content: space-evenly;
}

.leaders {
  margin: auto;
  width: 20rem;
}
.leader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }
}
